import gql from 'graphql-tag';

import { ADMIN_FRAGMENT } from '../fragments';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
      admin {
        ...AdminFragment
      }
    }
  }
  ${ADMIN_FRAGMENT}
`;
