import React, { memo, useCallback } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { View, StyleSheet } from 'react-native';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { Text, Button } from '../core-ui';
import { idDays, enDays, idMonths, enMonths } from '../constants/date';
import { spacing } from '../constants/theme';
import { useAuth } from '../context';
import { RoleAdmin } from '../generated/globalTypes';

import Header from './Header';

type Props = {
  backDestinationText?: string;
  showBackButton?: boolean;
  onBackButtonPress?: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    alignSelf: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const HeaderNavigationBar = memo((props: Props) => {
  const { name, role, logout } = useAuth();
  const history = useHistory();
  let {
    backDestinationText = t(['Admin Hub', 'Admin Hub']),
    showBackButton = true,
    onBackButtonPress,
  } = props;
  let classes = useStyles();

  let today = new Date();
  let date = t(['{idDay} {date} {idMonth}', '{enDay} {date} {enMonth}'], {
    idDay: idDays[today.getDay()],
    enDay: enDays[today.getDay()],
    date: today.getDate(),
    idMonth: idMonths[today.getMonth()],
    enMonth: enMonths[today.getMonth()],
  });
  let year = today.getFullYear();

  const onLogout = () => {
    logout();
  };

  const onBack = useCallback(() => {
    if (history.location.pathname !== '/') {
      history.push('/');
    }
  }, [history]);

  return (
    <View style={[styles.rowView, styles.headerWrapper]}>
      {showBackButton ? (
        <IconButton
          data-cy="header-back-button"
          onClick={onBackButtonPress ?? onBack}
          classes={{ root: classes.root }}
          disableTouchRipple={true}
        >
          <ArrowBackIcon fontSize="default" />
          <Text bold size="medium" style={styles.backText}>
            {t(['Kembali ke {destination}', 'Back to {destination}'], {
              destination: backDestinationText,
            })}
          </Text>
        </IconButton>
      ) : (
        <Header
          title="Admin Hub"
          subtitle={t(['Mulai di sini', 'Start here'])}
          subtitleColor="default"
        />
      )}
      <View style={styles.header}>
        <View
          style={[
            styles.rowView,
            styles.centerAligned,
            styles.smallRowSpacing,
            styles.textSpacing,
          ]}
        >
          <Text size="medium">{t(['Hai', 'Hello'])} </Text>
          <Text bold size="medium" style={styles.adminText}>
            {role === RoleAdmin.PRINCIPAL ? 'Principal' : 'Admin'}
          </Text>
          <Button
            data-cy="header-log-out"
            preset="transparent"
            title={t(['Keluar', 'Logout'])}
            onPress={onLogout}
          />
        </View>
        <View style={[styles.rowView, styles.textSpacing]}>
          <Text size="medium">
            <Text bold size="medium">
              {date}{' '}
            </Text>
            {year}
          </Text>
        </View>
        <View style={styles.rowView}>
          <Text size="medium">
            <Text bold size="medium">
              User:{' '}
            </Text>
            <Text size="medium">{name}</Text>
          </Text>
        </View>
      </View>
    </View>
  );
});

let styles = StyleSheet.create({
  rowView: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textSpacing: { paddingBottom: spacing.xsmall },
  centerAligned: { alignItems: 'center' },
  smallRowSpacing: { paddingBottom: spacing.small },
  header: { alignItems: 'flex-end' },
  headerWrapper: { justifyContent: 'space-between' },
  backText: { marginLeft: 12 },
  adminText: {
    paddingRight: spacing.medium,
    marginRight: spacing.medium,
    borderRightWidth: 2,
  },
});

export default HeaderNavigationBar;
