import gql from 'graphql-tag';

export const CLAIM_VERIFICATION_REQUEST = gql`
  mutation ClaimVerificationRequest(
    $type: VerificationClaimType!
    $id: String!
  ) {
    requestVerificationClaim(type: $type, id: $id) {
      success
    }
  }
`;

export const UPDATE_OWNER = gql`
  mutation UpdateOwner(
    $verificationId: ID!
    $status: StatusVerify!
    $ownerData: OwnerInputData
    $note: String!
    $unverifiedReason: [UnverifiedReason!]
  ) {
    updateRequestVerificationOwner(
      verificationId: $verificationId
      status: $status
      ownerData: $ownerData
      note: $note
      unverifiedReason: $unverifiedReason
    ) {
      id
    }
  }
`;

export const UPDATE_REQUEST_VERIFICATION_STORE = gql`
  mutation UpdateRequestVerificationStore(
    $verificationId: ID!
    $status: StatusVerify!
    $existingStoreCode: String
    $storeData: StoreInputData
    $note: String
    $requestAgentData: RequestAgentVerificationInput!
    $validasiToko: String
    $validVisitation: ValidVisitation
    $unverifiedReason: [UnverifiedReason!]
  ) {
    updateRequestVerificationStore(
      verificationId: $verificationId
      status: $status
      existingStoreCode: $existingStoreCode
      storeData: $storeData
      note: $note
      requestAgentData: $requestAgentData
      validasiToko: $validasiToko
      validVisitation: $validVisitation
      unverifiedReason: $unverifiedReason
    ) {
      id
    }
  }
`;
