import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import SubjectIcon from '@material-ui/icons/Subject';

import {
  Text,
  TextInput,
  Button,
  Infotip,
  AttachmentModal,
  DatePicker,
} from '../../core-ui';
import { SegmentedControl } from '../../components';
import { Dropdown, UnverifiedCategories } from '../../components/dropdowns';
import { useSnackbar } from '../../context';
import { toPascalCase } from '../../helpers';
import { colors, spacing } from '../../constants/theme';
import { documentType } from '../../constants/documentType';
import { UPDATE_OWNER } from '../../graphql/mutations';
import { attachment, close, doneCheck } from '../../../../../assets';
import {
  DocumentProblemType,
  Gender,
  IdCardType,
  StatusVerify,
} from '../../../../generated/globalTypes';
import { VerificationRequests_requestVerificationAdvance_row as Data } from '../../../../generated/VerificationRequests';
import {
  UpdateOwner,
  UpdateOwnerVariables,
} from '../../../../generated/UpdateOwner';
import { MIN_KTP_LENGTH } from '../../constants/ktpMinLength';
import validateEmail from '../../../../helpers/validateEmail';
import { unverifiedDocumentType } from '../../constants/unverifiedDocument';
import validateIdentity from '../../../../helpers/validateIdentity';
import { genderType } from '../../constants/genderType';
import { fonts } from '../../../../constants/theme';

type Attachment = { name: string; image: string };
type Props = {
  data: Data;
  unclaimLoading?: boolean;
  viewMode?: boolean;
  setFormDirty?: (value: boolean) => void;
  onUnclaim?: () => void;
  onUpdate?: () => void;
};

const ownerStatusOptions = [StatusVerify.UNVERIFIED, StatusVerify.VERIFIED];
const ownerStatusOptionTexts = ownerStatusOptions.map(toPascalCase);

const OwnerDataForm = memo((props: Props) => {
  const {
    data,
    unclaimLoading,
    viewMode = false,
    setFormDirty,
    onUnclaim,
    onUpdate,
  } = props;
  const { openSnackbar } = useSnackbar();

  let [attachmentName, setAttachmentName] = useState('');
  let [openModal, setOpenModal] = useState(false);
  let [image, setImage] = useState<string | null>(null);
  const [tokoName, setTokoName] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [year, setYear] = useState('');
  const [attachments, setAttachments] = useState<Array<Attachment>>([]);
  const [status, setStatus] = useState(0);
  const [note, setNote] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const [
    selectedDocumentType,
    setSelectedDocumentType,
  ] = useState<IdCardType | null>(null);
  const [gender, setGender] = useState<Gender | null>(null);
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  let [unverifiedDocument, setUnverifiedDocument] = useState<Array<string>>([
    '',
  ]);
  let [unverifiedProblemFound, setUnverifiedProblem] = useState<Array<string>>([
    '',
  ]);
  let [nextActionDesc, setNextActionDesc] = useState<Array<string>>(['']);
  let emailCheck = email === '' ? true : validateEmail(email);
  let identityCheck = validateIdentity(id);

  const centangPernyataan = data.user?.agreementStatementRegal
    ? data.user.agreementStatementRegal.agreementStatementApprove || false
    : data.user?.profile?.agreementStatement || false;

  let breakpointDate = new Date('September 20, 2021 00:00:00');
  let userCreatedAt = new Date(data.createdAt);

  const onUpdateError = useCallback(() => {
    openSnackbar({
      variant: 'error',
      message: t([
        'Gagal memperbarui data. Mohon coba lagi nanti.',
        'Failed to update data. Please try again later.',
      ]),
    });
  }, [openSnackbar]);
  const [updateData, { loading }] = useMutation<
    UpdateOwner,
    UpdateOwnerVariables
  >(UPDATE_OWNER, {
    onCompleted: onUpdate,
    onError: onUpdateError,
  });
  const onUpdateData = useCallback(() => {
    let unverifiedReason = [];
    for (let i = 0; i < nextActionDesc.length; i++) {
      let tempUnverifiedReason = {
        reasonType:
          unverifiedDocument[i] === 'KTP'
            ? DocumentProblemType.KTP
            : unverifiedDocument[i] === 'SELFIE'
            ? DocumentProblemType.SELFIE
            : DocumentProblemType.OWNER_NAME,
        nextAction: nextActionDesc[i],
        reason: unverifiedProblemFound[i],
      };
      unverifiedReason.push(tempUnverifiedReason);
    }

    updateData({
      variables: {
        verificationId: data.id,
        ownerData: {
          name,
          phoneNumber: phone,
          email,
          yearEstablished: year === '' ? '-' : year,
          idCardNumber: id,
          idCardType: selectedDocumentType,
          birthDate: birthDate,
          gender: gender ? gender : Gender.MALE,
        },
        status: ownerStatusOptions[status],
        note,
        unverifiedReason,
      },
    });
  }, [
    updateData,
    data.id,
    name,
    phone,
    email,
    year,
    id,
    selectedDocumentType,
    status,
    note,
    gender,
    birthDate,
    nextActionDesc,
    unverifiedDocument,
    unverifiedProblemFound,
  ]);

  const onOpenAttachment = useCallback((image: string, name: string) => {
    setOpenModal(true);
    setImage(image);
    setAttachmentName(name);
  }, []);
  const onCloseAttachment = useCallback(() => setOpenModal(false), []);

  useEffect(() => {
    // NOTE: update form states when data changes (select a different verification of Owner type)
    setStoreCode(data.user?.store?.storeManagerBulk?.storeCode ?? '');
    setTokoName(data.user?.store?.storeManagerBulk?.storeName ?? '');
    setName(data.user?.profile?.name ?? '');
    setPhone(data.user?.phoneNumber ?? '');
    setEmail(data.user?.profile?.email ?? '');
    setId(data.user?.profile?.idCardNumber ?? '');
    setYear(data.user?.store?.storeManagerBulk?.yearEstablished ?? '');
    setSelectedDocumentType(data.user?.profile?.idCardType ?? null);
    let ownerAttachments: Array<Attachment> = [];
    if (data.user?.profile?.avatar) {
      ownerAttachments.push({
        name: t(['Swafoto', 'Selfie']),
        image: data.user.profile.avatar,
      });
    }
    if (data.user?.profile?.idCardPhoto) {
      ownerAttachments.push({
        name: t(['Kartu Identitas', 'ID Card']),
        image: data.user.profile.idCardPhoto,
      });
    }
    if (data.user?.store?.proofOfBusiness) {
      ownerAttachments.push({
        name: t(['Bukti Bisnis', 'Proof of Business']),
        image: data.user?.store?.proofOfBusiness,
      });
    }
    setAttachments(ownerAttachments);
    setStatus(data.status === StatusVerify.VERIFIED ? 1 : 0);
    setNote(data.note);
    setUnverifiedDocument(['']);
    setUnverifiedProblem(['']);
    setNextActionDesc(['']);
    setGender(data.user?.profile?.gender ?? null);
    setBirthDate(data.user?.profile?.birthDate ?? null);
  }, [data]);

  let isUnverifiedCategoriesFilled =
    status === 0 ? nextActionDesc.indexOf('') > -1 : false;

  const submitDisabled = useMemo(
    () =>
      status !== 0
        ? !name ||
          !phone ||
          !id ||
          !note ||
          id.length < MIN_KTP_LENGTH ||
          !identityCheck ||
          !emailCheck ||
          !gender ||
          !birthDate ||
          isUnverifiedCategoriesFilled
        : !name ||
          !phone ||
          !id ||
          !note ||
          id.length < MIN_KTP_LENGTH ||
          !identityCheck ||
          !emailCheck ||
          isUnverifiedCategoriesFilled,
    [
      name,
      phone,
      id,
      note,
      gender,
      birthDate,
      status,
      emailCheck,
      isUnverifiedCategoriesFilled,
      identityCheck,
    ],
  );

  const onValueChange = useCallback(
    <T extends unknown>(setter: Dispatch<SetStateAction<T>>) => (params: T) => {
      setFormDirty?.(true);
      setter(params);
    },
    [setFormDirty],
  );
  const onNameChange = useMemo(() => onValueChange(setName), [onValueChange]);
  const onPhoneChange = useMemo(() => onValueChange(setPhone), [onValueChange]);
  const onEmailChange = useMemo(() => onValueChange(setEmail), [onValueChange]);
  const onIdChange = useMemo(() => onValueChange(setId), [onValueChange]);

  const onStatusChange = useMemo(() => onValueChange(setStatus), [
    onValueChange,
  ]);
  const onNoteChange = useMemo(() => onValueChange(setNote), [onValueChange]);

  const renderUnverifiedCategories = () => {
    let unverifiedDropdown = unverifiedDocument.map((_, index) => {
      return (
        <UnverifiedCategories
          key={index}
          setUnverifiedDocument={setUnverifiedDocument}
          setUnverifiedProblem={setUnverifiedProblem}
          setNextActionDesc={setNextActionDesc}
          unverifiedDocument={unverifiedDocument}
          unverifiedProblem={unverifiedProblemFound}
          options={unverifiedDocumentType}
          disabledDocumentType={viewMode}
          disabledProblem={viewMode}
          index={index}
          nextAction={nextActionDesc}
        />
      );
    });
    return unverifiedDropdown;
  };

  return (
    <>
      <AttachmentModal
        open={openModal}
        title={attachmentName}
        onClose={onCloseAttachment}
        image={image || ''}
      />
      <View style={styles.root}>
        {!viewMode && (
          <>
            <View style={styles.title}>
              <Text bold size="s" color="white">
                {t(['Data Pemilik', 'Owner Data'])}
              </Text>
            </View>
            <View style={[styles.topContainer, styles.border]}>
              <View style={[styles.borderTopZero, styles.lPadding]}>
                <Text style={styles.tokoName}>
                  {t(['Nama Toko', 'Store Name'])}
                </Text>
                <Text bold size="m">
                  {tokoName || '-'}
                </Text>
              </View>
              <View style={[styles.borderTopZero, styles.lPadding]}>
                <Text style={styles.tokoName}>
                  {t(['Kode Toko', 'Store Code'])}
                </Text>
                <Text bold size="m">
                  {storeCode || '-'}
                </Text>
              </View>
            </View>
          </>
        )}
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <Text bold size="m" style={styles.lBottomSpacing}>
            {t(['Informasi Pemilik', 'Owner Information'])}
          </Text>
          <TextInput
            data-cy="owner-verification-name"
            containerStyle={styles.lBottomSpacing}
            value={name}
            onChangeText={onNameChange}
            label={t(['Nama', 'Name'])}
            disabled={true}
            required
          />
          <TextInput
            data-cy="owner-verification-phone"
            type="number"
            containerStyle={styles.lBottomSpacing}
            value={phone}
            onChangeText={onPhoneChange}
            label={t(['Nomor HP', 'Mobile'])}
            disabled
            required
          />
          <TextInput
            data-cy="owner-verification-email"
            containerStyle={
              emailCheck ? styles.lBottomSpacing : styles.sBottomSpacing
            }
            value={email}
            onChangeText={onEmailChange}
            label={t(['Email (opsional)', 'Email (optional)'])}
            disabled={viewMode}
          />
          {!emailCheck && (
            <Text bold style={styles.formatEmailSalah}>
              Format Email Salah!
            </Text>
          )}
          <Dropdown
            onChange={(value: IdCardType) => {
              setSelectedDocumentType(value);
            }}
            label={t(['Tipe Dokumen', 'Document Type'])}
            value={selectedDocumentType}
            options={documentType}
            containerStyle={styles.cardType}
            disabled={viewMode}
          />
          <TextInput
            data-cy="owner-verification-id"
            containerStyle={styles.lBottomSpacing}
            value={id}
            onChangeText={onIdChange}
            label={t(['Kartu Identitas/SIM/Paspor', 'ID Card/SIM/Passport'])}
            error={id.length < MIN_KTP_LENGTH || !identityCheck}
            helperText={
              id.length < MIN_KTP_LENGTH
                ? t([
                    'Nomor Kartu Identitas minimal 8 karakter.',
                    'ID number must be minimum of 8 characters.',
                  ])
                : !identityCheck
                ? t(['Format Identitas Salah. ', 'Wrong Identity Format.'])
                : ''
            }
            disabled={viewMode}
            onInput={(e) => {
              (e.target as HTMLTextAreaElement).value = (e.target as HTMLTextAreaElement).value
                .toString()
                .slice(0, 19);
            }}
            required
          />
          <View>
            <Dropdown
              onChange={(value: Gender) => {
                setGender(value);
              }}
              label={t(['Jenis Kelamin', 'Gender'])}
              value={data.user?.profile?.gender || gender}
              options={genderType}
              containerStyle={styles.cardType}
              disabled={viewMode}
              required={status !== 0}
              error={status !== 0 ? gender == null : false}
              helperText={
                status !== 0
                  ? gender == null
                    ? t(['Kolom harus diisi', 'This field must be filled'])
                    : ''
                  : ''
              }
            />
          </View>
          <View>
            <DatePicker
              data-cy="owner-verification-birthdate"
              selectedDate={data.user?.profile?.birthDate || birthDate}
              onChange={setBirthDate}
              label={t(['Tanggal Lahir', 'Birth Date'])}
              style={styles.birthDate}
              required={status !== 0}
              error={status !== 0 ? birthDate == null : false}
              helperText={
                status !== 0
                  ? birthDate == null
                    ? t(['Kolom harus diisi', 'This field must be filled'])
                    : ''
                  : ''
              }
            />
          </View>
          <TextInput
            data-cy="owner-verification-address"
            value={data.user?.store?.storeManagerBulk?.address || ''}
            onChangeText={() => {}}
            label={'Address'}
            disabled={true}
          />
          <View style={styles.provinceAndCityContainer}>
            <TextInput
              data-cy="owner-verification-province"
              value={data.user?.store?.storeManagerBulk?.province || ''}
              onChangeText={() => {}}
              label={'Provinsi'}
              disabled={true}
              containerStyle={styles.provinceAndCityTextBox}
            />
            <TextInput
              data-cy="owner-verification-city"
              value={data.user?.store?.storeManagerBulk?.city || ''}
              onChangeText={() => {}}
              label={'Kabupaten/Kota'}
              disabled={true}
              containerStyle={styles.provinceAndCityTextBox}
            />
          </View>
          {userCreatedAt.getTime() > breakpointDate.getTime() ? (
            <View>
              {centangPernyataan ? (
                <View style={styles.attachmentRow}>
                  <View style={{ paddingBottom: 8 }}>
                    <Image source={doneCheck} style={styles.doneIcon} />
                  </View>
                  <Text bold style={styles.sudahCentangPernyataan}>
                    Sudah Centang Kalimat Pernyataan
                  </Text>
                </View>
              ) : (
                <View style={styles.attachmentRow}>
                  <View style={{ paddingBottom: 8 }}>
                    <Image source={close} style={styles.doneIcon} />
                  </View>
                  <Text bold style={styles.belumCentangPernyataan}>
                    Belum Centang Kalimat Pernyataan
                  </Text>
                </View>
              )}
            </View>
          ) : (
            <View>
              {centangPernyataan ? (
                <View style={styles.attachmentRow}>
                  <View style={{ paddingBottom: 8 }}>
                    <Image source={doneCheck} style={styles.doneIcon} />
                  </View>
                  <Text bold style={styles.sudahCentangPernyataan}>
                    Sudah Centang Kalimat Pernyataan (User Menggunakan Dokumen
                    Bisnis)
                  </Text>
                </View>
              ) : (
                <View style={styles.attachmentRow}>
                  <View style={{ paddingBottom: 8 }}>
                    <Image source={close} style={styles.doneIcon} />
                  </View>
                  <Text bold style={styles.belumCentangPernyataan}>
                    Belum Centang Kalimat Pernyataan (User Menggunakan Dokumen
                    Bisnis)
                  </Text>
                </View>
              )}
            </View>
          )}
          <View style={styles.viewPernyataan}>
            <Text bold style={styles.pernyataanHeader}>
              Kalimat Pernyataan
            </Text>
            <Text style={styles.pernyataan}>
              {centangPernyataan
                ? data.user?.agreementStatementRegal?.agreementStatement || ''
                : ''}
            </Text>
          </View>
        </View>
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <Text bold size="m" style={styles.lBottomSpacing}>
            {`Attachments (${attachments.length})`}
          </Text>
          <View style={[styles.row, styles.attachments]}>
            {attachments.map((item, index) => (
              <Button
                key={index}
                titleTextProps={{ color: 'secondaryBlack' }}
                style={[
                  styles.button,
                  index > 0 ? styles.butonSpacing : undefined,
                ]}
                iconAndText
                icon={
                  <Image source={attachment} style={styles.attachmentIcon} />
                }
                preset="transparent"
                title={item.name}
                onPress={() => onOpenAttachment(item.image, item.name)}
              />
            ))}
          </View>
        </View>
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <View
            style={[styles.row, styles.lBottomSpacing, styles.centerAligned]}
          >
            <Text bold size="m" style={{ marginRight: spacing.xxs }}>
              {t(['Status Pemilik', 'OWNER Status'])}
            </Text>
            <Infotip />
          </View>
          <SegmentedControl
            data-cy="owner-verification-status"
            containerStyle={styles.statusContainer}
            selected={status}
            setSelected={onStatusChange}
            options={ownerStatusOptionTexts}
          />
        </View>
        {status === 0 && (
          <View
            style={
              viewMode
                ? styles.viewMode
                : [styles.borderTopZero, styles.border, styles.lPadding]
            }
          >
            <Text bold size="m" style={styles.categoryUnverifiedText}>
              Kategori Unverified
            </Text>
            {renderUnverifiedCategories()}
          </View>
        )}
      </View>
      <View style={[styles.border, styles.bottomContainer]}>
        <TextInput
          data-cy="owner-verification-note"
          containerStyle={!viewMode && styles.lBottomSpacing}
          value={note}
          onChangeText={onNoteChange}
          leftNode={<SubjectIcon htmlColor={colors.border.primary} />}
          placeholder={t(['Catatan (Wajib)', 'Add Notes (Required)'])}
          disabled={viewMode}
          multiline
          rows={3}
        />
        {!viewMode && (
          <View style={[styles.row, { justifyContent: 'space-between' }]}>
            {onUnclaim && (
              <Button
                data-cy="owner-verification-unclaim"
                style={styles.buttonWidth}
                isLoading={unclaimLoading}
                preset="secondary"
                title={t(['Batal Klaim', 'Unclaim'])}
                onPress={onUnclaim}
              />
            )}
            <Button
              data-cy="owner-verification-submit"
              disabled={submitDisabled}
              isLoading={loading}
              style={styles.buttonWidth}
              preset="primary"
              title={t(['Perbarui Data', 'Update Data'])}
              onPress={onUpdateData}
            />
          </View>
        )}
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  root: { width: 529, backgroundColor: colors.background.white },
  row: { flexDirection: 'row' },
  buttonWidth: {
    width: 230,
  },
  title: {
    height: 42,
    justifyContent: 'center',
    backgroundColor: colors.background.orange,
    alignItems: 'center',
  },
  borderTopZero: {
    borderTopWidth: 0,
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  birthDate: {
    fontSize: fonts.sizes.small,
    paddingBottom: 24,
  },
  lBottomSpacing: {
    marginBottom: spacing.l,
  },
  lPadding: {
    padding: spacing.l,
  },
  sBottomSpacing: {
    marginBottom: spacing.s,
  },
  button: {
    marginBottom: spacing.s,
    minWidth: 149,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  butonSpacing: { marginLeft: spacing.m },
  bottomContainer: {
    marginTop: 24,
    padding: 24,
    width: 529,
    backgroundColor: colors.background.white,
  },
  attachmentIcon: { height: 24, width: 24, marginRight: 8 },
  doneIcon: {
    height: 20,
    width: 20,
    marginRight: 2,
  },
  attachments: { flexWrap: 'wrap' },
  tokoName: { paddingBottom: spacing.xxs },
  centerAligned: { alignItems: 'center' },
  statusContainer: { width: '100%' },
  viewMode: {
    borderTopColor: colors.button.border.primary,
    borderTopWidth: 1,
    paddingTop: spacing.l,
    paddingBottom: spacing.m,
  },
  cardType: { paddingBottom: 12 },
  pernyataanHeader: {
    textAlign: 'left',
    width: '80%',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
    paddingBottom: 11,
  },
  pernyataan: {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: 20,
  },
  viewPernyataan: {
    borderTopColor: colors.button.border.primary,
    borderTopWidth: 1,
    paddingTop: 20,
    paddingBottom: 8,
  },
  sudahCentangPernyataan: {
    fontSize: 14,
    color: colors.icon.acceptGreen,
    lineHeight: 17,
    paddingBottom: 26,
  },
  attachmentRow: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 17,
  },
  belumCentangPernyataan: {
    fontSize: 14,
    color: colors.icon.declineRed,
    lineHeight: 17,
    paddingBottom: 26,
  },
  formatEmailSalah: {
    fontSize: 12,
    color: colors.icon.declineRed,
    lineHeight: 17,
    paddingBottom: 26,
  },
  categoryUnverifiedText: { paddingBottom: 24 },
  topContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  provinceAndCityTextBox: { width: 230 },
  provinceAndCityContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
});

export default OwnerDataForm;
