import React, { ReactNode, useState } from 'react';
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  StyleSheet,
} from 'react-native';

import { Text, Button } from '../core-ui';
import { colors, theme } from '../constants/theme';

import Header from './Header';

type SearchProps = {
  'data-cy'?: string;
  children?: ReactNode;
  disabled?: boolean;
  title?: string;
  containerStyle: StyleProp<ViewStyle>;
  onClearPress: () => void;
  onSearchPress: () => void;
  onChangeTab: (tab: ActiveContent) => void;
};

export enum ActiveContent {
  POINT = 'point',
  ORDER = 'order',
  VERIF = 'verif',
}

export default function SearchHeaderReferral(props: SearchProps) {
  let {
    children,
    title,
    containerStyle,
    onClearPress,
    onSearchPress,
    disabled,
    onChangeTab,
  } = props;
  let leftSideCount = 1;
  let leftSide = children;
  let rightSide = null as ReactNode;
  if (Array.isArray(children)) {
    let filteredChildren = children.filter((item) => item != null);
    leftSideCount = Math.ceil(filteredChildren.length / 2);
    leftSide = filteredChildren.slice(0, leftSideCount);
    rightSide = filteredChildren.slice(leftSideCount);
  }

  const [activeContent, setActiveContent] = useState<ActiveContent>(
    ActiveContent.POINT,
  );
  return (
    <View style={containerStyle}>
      {title && <Header title={t(['Cari', 'Search'])} subtitle={title} />}
      <View
        style={{
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderBottomColor: colors.border.primary,
          marginTop: 20,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContent.POINT);
            onChangeTab(ActiveContent.POINT);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContent.POINT && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContent.POINT}
              size="mediumLarge"
            >
              Loyalty Point
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContent.ORDER);
            onChangeTab(ActiveContent.ORDER);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContent.ORDER && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContent.ORDER}
              size="mediumLarge"
            >
              Order
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContent.VERIF);
            onChangeTab(ActiveContent.VERIF);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContent.VERIF && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContent.VERIF}
              size="mediumLarge"
            >
              Verifikasi Pemilik Toko
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.row}>
        <View style={styles.flex} testID="leftSide">
          {leftSide}
        </View>
        <View style={styles.mediumHorizontalSpacing} />
        <View style={styles.flex} testID="rightSide">
          {rightSide}
          <View style={styles.row}>
            <View style={[styles.row, styles.flex]}>
              <Button
                disabled={disabled}
                data-cy={`${props['data-cy']}-submit`}
                title={t(['Cari', 'Search'])}
                style={styles.flex}
                onPress={onSearchPress}
              />
              <View style={styles.smallHorizontalSpacing} />
              <Button
                data-cy={`${props['data-cy']}-clear`}
                preset="secondary"
                title={t(['Hapus', 'Clear'])}
                style={styles.flex}
                onPress={onClearPress}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headButton: {
    paddingBottom: 16,
    marginRight: 36,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  row: { flexDirection: 'row' },
  flex: { flex: 1 },
  smallHorizontalSpacing: { width: theme.spacing.small },
  mediumHorizontalSpacing: { width: theme.spacing.medium },
});
